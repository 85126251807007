import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import LazyLoad from '../components/LazyLoad';
const HomePage = lazy(() => import('.././Pages/HomePage'));
const PresidencyPage = lazy(() =>
  import('../Pages/Presidency' /* webpackChunkName: "PresidencyPage"*/)
);
const VicePresidencyPage = lazy(
  () =>
    import(
      '../Pages/VicePresidency'
    ) /* webpackChunkName: "VicePresidencyPage"*/
);
const CandidateProfile = lazy(
  () =>
    import(
      '../components/profile/Profile'
    ) /* webpackChunkName: "CandidateProfile"*/
);
const NotFound = lazy(
  () => import('../Pages/NotFound') /* webpackChunkName: "NotFound"*/
);
const ComingSoon = lazy(
  () => import('../Pages/ComingSoon') /* webpackChunkName: "ComingSoon"*/
);
const ChooseState = lazy(
  () => import('../components/ChooseState') /* webpackChunkName: "ChooseState"*/
);
const Senate = lazy(
  () => import('../Pages/Senate') /* webpackChunkName: "Senate"*/
);
const Footer = lazy(
  () => import('../components/footer/Footer') /* webpackChunkName: "Footer"*/
);
const Governors = lazy(
  () => import('../Pages/Governors') /* webpackChunkName: "Governors"*/
);
const DeputyGovernors = lazy(
  () =>
    import('../Pages/DeputyGovernors') /* webpackChunkName: "DeputyGovernors"*/
);
const StateReps = lazy(
  () => import('../Pages/StateReps') /* webpackChunkName: "StateReps"*/
);
const FederalReps = lazy(
  () => import('../Pages/FederalReps') /* webpackChunkName: "FederalReps"*/
);

// ********************************************
// **track the pages that are visited the most (Start)
// ********************************************
const TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID; // OUR_TRACKING_ID

function App() {
  useEffect(() => {
    import('react-ga').then(ga => {
      ga.initialize(TRACKING_ID);
      ga.pageview(window.location.pathname + window.location.search);
    });
  }, []);

  // ********************************************
  // **track the pages that are visited the most (End)
  // ********************************************

  const ScrollToTop = ({ children }) => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return children;
  };

  return (
    <Suspense fallback={<LazyLoad />}>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="presidency" element={<PresidencyPage />} />
            <Route path="vice-presidency" element={<VicePresidencyPage />} />
            <Route
              path="vice-presidency/:profile"
              element={<CandidateProfile />}
            />
            <Route path="presidency/:profile" element={<CandidateProfile />} />
            <Route path="governors" element={<ChooseState />} />
            <Route path="governors/:state" element={<Governors />} />
            <Route
              path="governors/:state/:profile"
              element={<CandidateProfile />}
            />
            <Route path="deputy_governors" element={<ChooseState />} />
            <Route
              path="deputy_governors/:state"
              element={<DeputyGovernors />}
            />
            <Route
              path="deputy_governors/:state/:profile"
              element={<CandidateProfile />}
            />
            <Route path="senate" element={<ChooseState />} />
            <Route path="senate/:state" element={<Senate />} />
            <Route path="state-reps" element={<ChooseState />} />
            <Route path="state-reps/:state" element={<StateReps />} />
            <Route path="federal-reps" element={<ChooseState />} />
            <Route path="federal-reps/:state" element={<FederalReps />} />
            <Route path="coming-soon" element={<ComingSoon />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </ScrollToTop>
        <Footer />
      </BrowserRouter>
    </Suspense>
  );
}

export default App;
