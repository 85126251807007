import { useState, useRef, useEffect, memo } from "react";


export const Spinner = ({color}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="3.5rem"
      height="3.5rem"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className={`${color}`}
        >
          <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#076187"
            stroke-width="10"
            r="35"
            stroke-dasharray="164.93361431346415 56.97787143782138"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              repeatCount="indefinite"
              dur="0.5s"
              values="0 50 50;360 50 50"
              keyTimes="0;1"
            ></animateTransform>
          </circle>
        </svg>
  )
}


const LazyLoad = ({ children }) => {
  const [showComponent, setShowComponent] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    // Create an intersection observer
    const observer = new IntersectionObserver((entries) => {
      // Check if the component is in view
      if (entries[0].isIntersecting) {
        setShowComponent(true);
      }
    });

    // Observe the component element
    observer.observe(componentRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      {showComponent ? children : <div className="d-flex justify-content-center align-items-center"><Spinner color={`DacBlue`}/></div>}
      
      <div ref={componentRef} style={{ height: "1rem", width: "1rem" }}></div>
    </>
  );
};

export default memo(LazyLoad);
